<template>
  
  
  <h1 class="handwriting"><div class="memo">LilianaViolet<small>.com</small></div></h1>
</template>

<script>

</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Parisienne&display=swap");
body {
  background: linear-gradient(90deg, #e63bfd 50%, #f28cff 50%);
}
.handwriting { 
  font-family: 'Parisienne', cursive;
  font-size: 10vw;
  width: 90vw;
  height: 50vh;
  margin: 20vh auto;
  border: 10px solid rgb(238, 200, 235);
  background: rgb(238, 200, 235);;
  border-radius:20px;
}
.memo {
  line-height: 50vh;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
